const tenantCustonConfig = {
  brandName: "Compraqui",
  dropdownMenuItems: [
    "bankAccount",
    "config",
    "buyReader",
  ],
  automaticCashout: {
    isEnabled: false,
  },
  termsAndConditions: false,
  showBankAccountSelectForm: true,
  sales: {
    transactionReceipt: {
      summary: [
        {
          label: "Titular de tarjeta",
          method: (sale) => sale.payment.cardHolderName,
        },
        {
          label: "Tasa comisión",
          method: (sale) => sale.commission + "%",
        },
        {
          label: "Fecha de disponibilidad",
          method: (sale) => sale.availableDate,
        },
        {
          label: "N° de referencia",
          method: (sale) => sale.referenceNumber,
        },
        {
          label: "Nº de autorización",
          method: (sale) => sale.authorizationCode,
        },
      ],
    },
  },
  deposits: {
    options: {
      formats: [
        { value: 'xls', label: 'Excel' },
        { value: 'pdf', label: 'PDF' }
      ],
    },
  },
  currencyDecimals: false,
  currencies: [
    {value: 152, label: "Peso Chileno (CLP)", code: "CLP", symbol: "$", digits: "0"}
  ]
};

const tenantTableColumns = {
  salesColumns: ['description', 'payment', 'date', 'amount'],
  depositColumns: ['availableDate', 'toDeposit', 'deposit', 'status'],
};

export { tenantCustonConfig, tenantTableColumns };
